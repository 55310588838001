import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { Title, Button } from "../styles/baseStyles.js"
import { GatsbyImage } from "gatsby-plugin-image"
import Link from "./link"

export default function Portfolio() {
  const { portfolio } = useStaticQuery(graphql`
    {
      portfolio: contentfulPortfolio {
        title
        desc {
          desc
        }
        items: portfolioItems {
          id
          title
          image {
            gatsbyImageData(placeholder: BLURRED, formats: [WEBP, PNG])
          }
          fields {
            slug
          }
        }
      }
    }
  `)
  let items = portfolio.items.slice(0, 3)
  return (
    <PortfolioContainer>
      <Head>
        <Title>{portfolio.title}</Title>
        <Column>
          <Link to="/portfolio">
            <Button secondary>View All</Button>
          </Link>
          <Info>{portfolio.desc.desc}</Info>
        </Column>
      </Head>
      <Grid>
        {items.map(item => (
          <Item key={item.id}>
            <Link to={`/portfolio/${item.fields.slug}`}>
              <GatsbyImage
                placeholder="BLURRED"
                image={item.image.gatsbyImageData}
                style={{ width: "100%" }}
              />
              <Name>{item.title}</Name>
            </Link>
          </Item>
        ))}
      </Grid>
    </PortfolioContainer>
  )
}

const PortfolioContainer = styled.div`
  margin-bottom: 11rem !important;
  @media screen and (max-width: 880px) {
    margin-bottom: 7rem !important;
  }
`
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2.5rem;
  @media screen and (max-width: 880px) {
    margin-bottom: 2rem;
    ${Title} {
      margin-left: 1.5rem;
    }
  }
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  a {
    display: block;
    margin-left: auto;
  }
`
const Info = styled.h4`
  margin-top: 1.5rem;
  font-size: 1.8rem;
  font-weight: 500;
  @media screen and (max-width: 880px) {
    font-size: 1.7rem;
    margin-top: 1rem;
		text-align: right;
		margin-right .5rem;
	}
	@media screen and (max-width: 580px){
		display: none;
	}
`
const Grid = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -1rem;
  @media screen and (max-width: 800px) {
    margin: 0 auto;
    justify-content: flex-start;
  }
`
const Item = styled.div`
  position: relative;
  flex: 0 0 calc(33% - 1rem);
  &:not(:last-child) {
    margin-right: 2rem;
  }
  img {
    height: 60rem;
    object-fill: cover;
    z-index: -1;
    border-radius: 3px;
  }
  @media screen and (max-width: 880px) {
    img {
      height: auto;
    }
  }

  @media screen and (max-width: 800px) {
    flex: 0 0 calc(50% - 0.75rem);
    &:nth-child(3) {
      display: none;
    }
    img {
      height: 60rem;
    }
  }

  @media screen and (max-width: 520px) {
    flex: 0 0 calc(50% - 0.5rem);
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`
const Name = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
  padding: 1.85rem 0.5rem;
`
