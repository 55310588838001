import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { Title } from "../styles/baseStyles.js"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Navigation } from "swiper"
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5"
import "swiper/css"

export default function DesignProcess() {
  const { process } = useStaticQuery(graphql`
    {
      process: contentfulProcess {
        title
        desc {
          desc
        }
        image {
          gatsbyImageData(formats: WEBP, placeholder: BLURRED)
        }
        items: processItem {
          id
          title
          desc {
            desc
          }
        }
      }
    }
  `)
  const swiperRef = useRef(null)
  const destroy = swiperRef?.current?.destroy || undefined

  useEffect(() => {
    return () => {
      destroy && destroy()
    }
  })
  return (
    <DesignProcessContainer>
      <Title>{process.title}</Title>
      <Main>
        <GatsbyImage
          placeholder="blurred"
          image={process.image.gatsbyImageData}
          alt="building with lighting design"
        />
        <p>{process.desc.desc}</p>
      </Main>
      <Controls>
        <div onClick={() => swiperRef.current.swiper.slidePrev()}>
          <IoArrowBackSharp />
        </div>
        <div onClick={() => swiperRef.current.swiper.slideNext()}>
          <IoArrowForwardSharp />
        </div>
      </Controls>
      <Grid>
        <Swiper
          modules={[Autoplay, Navigation]}
          spaceBetween={50}
          slidesPerView={3}
          breakpoints={{
            920: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            620: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            320: {
              slidesPerView: 1,
            },
          }}
          autoplay={true}
          speed={400}
          delay={9000}
          ref={swiperRef}
        >
          {process.items.map((item, index) => (
            <SwiperSlide>
              <Card key={item.id}>
                <h2>{index + 1}</h2>
                <h4>{item.title}</h4>
                <p>{item.desc.desc}</p>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </DesignProcessContainer>
  )
}

const DesignProcessContainer = styled.div`
  position: relative;
  margin: 10rem 0 13rem 5rem !important;
  ${Title} {
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 880px) {
    margin: 0 0 7rem 3rem !important;
  }
  @media screen and (max-width: 620px) {
    margin: 0 0 6rem 4rem !important;
  }
  @media screen and (max-width: 480px) {
    margin: 0 1.25rem 6rem 4rem !important;
    ${Title} {
      margin-left: 1.5rem;
    }
  }
`
const Main = styled.div`
  position: relative;
  img {
    height: 42rem;
    width: 117rem;
    margin-left: 3rem;
    z-index: -1;
    object-position: bottom;
    @media screen and (max-width: 1170px) {
      width: 120rem;
    }
    @media screen and (max-width: 620px) {
      margin-left: -6rem;
    }
    @media screen and (max-width: 480px) {
      width: 95vw !important;
    }
  }
  p {
    position: absolute;
    bottom: -4rem;
    left: -4rem;
    background: rgba(0, 0, 0, 0.93);
    color: #fff;
    height: 19rem;
    width: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    font-size: 1.7rem;
    line-height: 1.7;
    border-radius: 5px;
    @media screen and (max-width: 480px) {
      width: 23rem;
    }
  }
`
const Controls = styled.div`
  position: absolute;
  bottom: 6rem;
  left: 2.5rem;
  cursor: pointer;
  display: flex;
	outline: none;
  svg {
    height: 44px;
    width: 44px;
    path {
      stroke-width: 32px;
    }
  }
  @media screen and (max-width: 480px) {
    bottom: 7rem;
    left: 1.5rem;
    svg {
			height: 32px;
			widthL 32px;
    }
  }
`

const Grid = styled.div`
  margin: 2rem 0 2rem 25rem;
  @media screen and (max-width: 480px) {
    margin-left: 22rem;
  }
`
const Card = styled.div`
  &:not(:last-child) {
    margin-right: 2rem;
  }

  h2 {
    font-size: 5rem;
    font-weight: 400;
  }
  h4 {
    font-size: 2rem;
    margin-top: -1rem;
    margin-left: 3rem;
  }
  p {
    margin: 1rem 0 0 1.25rem;
    @media screen and (max-width: 1170px) {
      width: 85%;
    }
  }
`
