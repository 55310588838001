import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button } from "../styles/baseStyles.js"
import Link from "./link"

export default function About() {
  const { about } = useStaticQuery(graphql`
    {
      about: contentfulAbout {
        title
        desc {
          desc
        }
        image {
          gatsbyImageData(
            formats: [WEBP, PNG]
            placeholder: BLURRED
            height: 633
          )
        }
      }
    }
  `)

  return (
    <AboutContainer>
      <Image>
        <GatsbyImage
          placeholder="blurred"
          image={about.image.gatsbyImageData}
          alt="image of buildng with lighting design"
          style={{ height: "100%" }}
          
        />
      </Image>
      <Desc>
        <h2>{about.title}</h2>
        <p>{about.desc.desc}</p>
        <Link to="/team">
          <Button hollow>Learn More</Button>
        </Link>
      </Desc>
    </AboutContainer>
  )
}

const AboutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    margin-bottom: 5rem !important;
  }
`
const Image = styled.div`
  flex: 0 0 44%;
  img {
    height: 55rem;
    //width: 55rem;
  }
  @media screen and (max-width: 1170px) {
    flex: 0 0 47%;
  }
`
const Desc = styled.div`
  flex: 0 0 50%;
  h2 {
    margin-bottom: 3rem;
  }
  p {
    font-size: 1.8rem;
    white-space: pre-wrap;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
  button {
    margin-top: 1rem;
  }
  @media screen and (max-width: 880px) {
    flex: 0 0 48%;
    p {
      width: 90%;
      //text-align: justify;
    }
  }
`
