import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Title } from "../styles/baseStyles"

export default function Offering() {
  const { service } = useStaticQuery(graphql`
    query {
      service: contentfulServices {
        title
        image {
          gatsbyImageData(placeholder: BLURRED, formats: [WEBP, PNG])
        }
        desc {
          desc
        }
        items: servicesItem {
          id
          title
          desc {
            desc
          }
          icon {
            gatsbyImageData(
              placeholder: TRACED_SVG
              formats: [WEBP, PNG]
              width: 128
            )
          }
        }
      }
    }
  `)
  return (
    <OfferingContainer>
      <Column>
        <Title>{service.title}</Title>
        <Info>{service.desc.desc}</Info>
        <ImageGrid>
          <div>
            <GatsbyImage
              placeholder="blurred"
              image={service.image[0].gatsbyImageData}
            />
          </div>
          <div>
            <GatsbyImage
              placeholder="blurred"
              image={service.image[1].gatsbyImageData}
            />
          </div>
        </ImageGrid>
      </Column>
      <Column>
        {service.items.map(item => (
          <Item key={item.id}>
            <Icon>
              <GatsbyImage
                placeholder="blurred"
                image={item.icon.gatsbyImageData}
              />
            </Icon>
            <Desc>
              <h4>{item.title}</h4>
              <p>{item.desc.desc}</p>
            </Desc>
          </Item>
        ))}
      </Column>
    </OfferingContainer>
  )
}

const OfferingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`
const Info = styled.p`
  font-size: 2rem;
  width: 60%;
  margin: 4rem 0 0 7rem;
  @media screen and (max-width: 880px) {
    width: 70%;
  }
`
const Column = styled.div`
  flex: 0 0 50%;
  @media screen and (max-width: 880px) {
    ${Title} {
      margin-left: 1.5rem;
    }
  }
  @media screen and (max-width: 680px) {
    &:first-child {
      display: flex;
      flex-wrap: wrap;
    }
  }
`
const ImageGrid = styled.div`
	display: flex;
	& > div:nth-child(1){
		flex: 0 0 35%;
		margin: 6rem 0 0 2rem;
			}
	& > div:nth-child(2){
		flex: 0 0 50%;
		margin 4rem 0 0 2rem;
	}

	@media screen and (max-width: 880px){
		margin-top: 3rem;
		& > div:nth-child(1){
			img{
			height: 25rem !important;
			}
		}
		& > div:nth-child(2){
			img {
				height: 47rem !important;
			}
		}
	}
	@media screen and (max-width: 680px) {
		flex: 0 0 100%;
		margin-bottom: 4rem;
		& > div:nth-child(1){
			margin: 2.5rem 1rem 0 22vw;
			flex: 0 0 25%;
			img{
				width: 100% !important;
				height: 22.5rem !important;
			}
		}
		& > div:nth-child(2){
			flex: 0 0 47%;
			margin: 0;
			img{
				width: 100% !important;
				height: 50rem !important;
			}
		}
	}
	@media screen and (max-width: 480px){
		margin-bottom: 5rem;
		& > div:nth-child(1) {
			margin: 2.5rem 1rem 0 18vw;
			img {
				height: 20rem !important;	
			}
		}
		& > div:nth-child(2) {
			img {
				height: 42rem !important;
			}
		}
	}
`
const Item = styled.div`
  margin: 2.5rem 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Icon = styled.div`
  flex: 0 0 15%;
  margin-right: 1.5rem;
  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  @media screen and (max-width: 480px) {
    img {
      width: 34px;
      height: 34px;
    }
  }
`
const Desc = styled.div`
  h4 {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 480px) {
    h4 {
      font-size: 1.9rem;
    }
    p {
      font-size: 1.8rem;
    }
  }
`
