import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button } from "../styles/baseStyles"
import Link from "./link"

export default function Header() {
  const { header } = useStaticQuery(graphql`
    {
      header: contentfulHeader {
        desc {
          desc
        }
        image {
          gatsbyImageData(
            formats: [WEBP, PNG]
            placeholder: BLURRED
            height: 620
          )
        }
      }
    }
  `)

  return (
    <HeaderContainer>
      <Desc>
        <Text>{header.desc.desc}</Text>
        <Link to="/contact">
          <Button primary>Get A Quote</Button>
        </Link>
      </Desc>
      <HeroImage>
        <GatsbyImage
          placeholder="blurred"
          image={header.image.gatsbyImageData}
          objectFit="cover"
          style={{ width: "100%" }}
          imgStyle={{ width: "100%" }}
          alt="image of building showing lighting design"
        />
      </HeroImage>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -7rem calc(-50vw + var(--container-width) / 2) 0 0;
  position: relative;
  @media screen and (max-width: 1170px) {
    margin: -7rem -5vw 0 0;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    margin: -7rem -5vw;
  }
`

const Desc = styled.div`
  flex: 0 0 40%;
  margin-left: 1rem;
  @media screen and (max-width: 1120px) {
    flex: 0 0 43%;
  }
  @media screen and (max-width: 1170px) {
    margin-left: 3rem;
  }
  @media screen and (max-width: 1024px) {
    flex: 0 0 100%;
    position: absolute;
    left: 5vw;
    bottom: 5%;
  }
`
const Text = styled.h1`
  font-size: 4.1rem;
  line-height: 1.3;
  font-weight: 700;
  color: var(--text-mid);
  margin-top: 4rem;
  margin-bottom: 3.5rem;
  white-space: pre-wrap;
  @media screen and (max-width: 1170px) {
    font-size: 4rem;
  }
  @media screen and (max-width: 1024px) {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
  }
`

const HeroImage = styled.div`
  flex: 0 0 60%;
  height: 43%;
  z-index: -1;
  @media screen and (max-width: 1120px) {
    flex: 0 0 57%;
    height: 40%;
  }
  img {
    z-index: -1;
    width: auto;
  }
  @media screen and (max-width: 1024px) {
    flex: 0 0 100%;
    width: 100%;
    img {
      width: 100vw !important;
    }
  }
  @media screen and (max-width: 780px) {
    img {
      min-height: 55vh !important;
    }
  }
  @media screen and (max-width: 480px) {
    img {
      min-height: 60vh !important;
    }
  }
`
