import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Title } from "../styles/baseStyles"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Navigation } from "swiper"
import "swiper/css"
import {
  IoIosArrowDroprightCircle,
  IoIosArrowDropleftCircle,
} from "react-icons/io"

export default function Future() {
  const { future } = useStaticQuery(graphql`
    {
      future: contentfulFuture {
        title {
          title
        }
        desc {
          desc
        }
        items: futureItems {
          title
          desc {
            desc
          }
          image {
            gatsbyImageData(placeholder: BLURRED, formats: [WEBP, PNG])
          }
        }
      }
    }
  `)

  const swiperRef = useRef(null)
  const destroy = swiperRef?.current?.destroy || undefined

  useEffect(() => {
    return () => {
      destroy && destroy()
    }
  })

  return (
    <FutureContainer>
      <Swiper
        modules={[Autoplay, Navigation]}
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{ delay: 2000 }}
        speed={500}
        loop={true}
        ref={swiperRef}
      >
        {future.items.map(item => (
          <SwiperSlide>
            <Image>
              <Box />
              <GatsbyImage
                placeholder="blurred"
                image={item.image.gatsbyImageData}
                alt={`${item.title} lighting design image`}
              />
            </Image>
            <Body>
              <h4>{item.title}</h4>
              <p>{item.desc.desc}</p>
            </Body>
          </SwiperSlide>
        ))}
      </Swiper>
      <Head>
        <Title>{future.title.title}</Title>
        <p>{future.desc.desc}</p>
      </Head>
      <Action>
        <TriggerButton onClick={() => swiperRef.current.swiper.slidePrev()}>
          <IoIosArrowDropleftCircle />
        </TriggerButton>
        <TriggerButton onClick={() => swiperRef.current.swiper.slideNext()}>
          <IoIosArrowDroprightCircle />
        </TriggerButton>
      </Action>
    </FutureContainer>
  )
}

const FutureContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 880px) {
    padding-top: 4rem;
  }
`

const Box = styled.div`
  position: absolute;
  height: 35rem;
  width: 20rem;
  background: rgba(0, 0, 0, 0.9);
  @media screen and (max-width: 530px) {
    display: none;
  }
`
const Image = styled.div`
  position: relative;
  img {
    height: 46rem;
    width: 35rem;
    position: absolute;
    top: 3rem;
    left: 3rem;
    @media screen and (max-width: 770px) {
      width: 35vw !important;
    }
    @media screen and (max-width: 530px) {
      left: 0;
    }
    @media screen and (max-width: 480px) {
      height: 48rem;
      width: 39vw !important;
    }
  }
`
const Body = styled.div`
  position: absolute;
  bottom: 0;
  left: 34%;
  p {
    width: 50%;
    margin: 1rem 0;
  }
  @media screen and (max-width: 880px) {
    left: 38vw !important;
  }
  @media screen and (max-width: 580px) {
    p {
      width: 60%;
    }
  }
  @media screen and (max-width: 480px) {
    left: 42vw !important;
    p {
      width: 85%;
      font-size: 1.8rem;
    }
  }
`
const Head = styled.div`
  p {
    font-size: 2rem;
    width: 80%;
    margin: 3rem 0;
  }
  ${Title} {
    white-space: pre-wrap;
  }
  position: absolute;
  top: 7rem;
  left: 50rem;
  @media screen and (max-width: 880px) {
    left: 45vw;
  }
  @media screen and (max-width: 650px) {
    left: 40vw;
    p {
      margin: 1.5rem 0;
      font-size: 1.75rem;
    }
  }
  @media screen and (max-width: 480px) {
    top: 6.5rem;
    p {
      width: 90%;
      margin: 0.75rem 0;
    }
    left: 46vw;
    ${Title} {
      font-size: 3.2rem;
    }
  }
`

const Action = styled.div`
  position: absolute;
  bottom: 6%;
  right: 10%;
  display: flex;
  @media screen and (max-width: 520px) {
    right: 4%;
    bottom: 5%;
  }
`
const TriggerButton = styled.div`
  cursor: pointer;
  z-index: 1;
  svg {
    height: 41px;
    width: 41px;
    @media screen and (max-width: 650px) {
      height: 32px;
      width: 32px;
    }
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
`
