import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Title, Button } from "../styles/baseStyles"
import Link from "./link"

export default function Scope() {
  const { scope } = useStaticQuery(graphql`
    {
      scope: contentfulScope {
        title
        desc {
          desc
        }
        items: scopeItems {
          id
          title
          desc {
            desc
          }
          image {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [WEBP, PNG]
              resizingBehavior: FILL
            )
          }
        }
      }
    }
  `)
  let items = scope.items.slice(0, 2)
  return (
    <ScopeContainer>
      <Column>
        <Title>{scope.title}</Title>
        <p>{scope.desc.desc}</p>
        <Link to="/services">
          <Button primary>Learn More</Button>
        </Link>
      </Column>
      <Column>
        {items.map(item => (
          <Card key={item.id}>
            <GatsbyImage
              placeholder="blurred"
              image={item.image.gatsbyImageData}
              style={{ width: "100%" }}
            />
            <h3>{item.title}</h3>
            <p>{item.desc.desc}</p>
          </Card>
        ))}
      </Column>
    </ScopeContainer>
  )
}

const ScopeContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 2rem;
    width: 60%;
    margin: 3rem 0;
    @media screen and (max-width: 680px) {
      width: 80%;
    }
  }
`
const Column = styled.div`
  flex: 0 0 50%;
  &:nth-child(2) {
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 1170px) {
    &:first-child {
      padding-left: 3rem;
    }
  }
  @media screen and (max-width: 880px) {
    &:nth-child(1) {
      flex: 0 0 45%;
    }
    &:nth-child(2) {
      flex: 0 0 55%;
    }
  }
  @media screen and (max-width: 680px) {
    &:nth-child(1) {
      flex: 0 0 calc(55% - 1rem);
      margin-right: 1rem;
    }
    &:nth-child(2) {
      flex: 0 0 45%;
    }
  }
  @media screen and (max-width: 480px) {
    &:nth-child(1) {
      flex: 0 0 55%;
    }
  }
`

const Card = styled.div`
  flex: 0 0 calc(50% - 1.5rem);
  img {
    height: 40rem;
  }
  h3 {
    margin: 1rem 0 0.5rem;
  }
  p {
    font-size: 1.6rem;
    margin: 0.5rem 0;
    width: 100%;
  }
  &:first-child {
    margin-right: 3rem;
  }
  @media screen and (max-width: 680px) {
    flex: 0 0 100%;
    &:not(:first-child) {
      display: none;
    }
  }
`
