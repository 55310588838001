import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import Header from "../components/header"
import About from "../components/about"
import Portfolio from "../components/portfolio"
import Offering from "../components/offering"
import DesignProcess from "../components/process"
import Scope from "../components/scope"
import Future from "../components/future"

export default function Home() {
  return (
    <>
      <Seo />
      <HomeContainer>
        <Header />
        <About />
        <Portfolio />
        <Offering />
        <DesignProcess />
        <Scope />
        <Future />
      </HomeContainer>
    </>
  )
}

const HomeContainer = styled.div`
  & > *:not(:first-child) {
    margin-bottom: 8rem;
    @media screen and (max-width: 880px) {
      margin-bottom: 4rem;
    }
  }
  & > *:last-child {
    margin-bottom: 15rem;
    @media screen and (max-width: 880px) {
      margin-bottom: 10rem;
    }
  }
  & > *:first-child {
    margin-bottom: 4.5rem;
  }
`
